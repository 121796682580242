import { start, registerApplication } from 'single-spa';
import 'babel-polyfill';
import { envVars } from './envVars';
import { defaultCustomProps } from '.';

declare global {
  interface Window {
    System: { import: Function };
    singleSpaNavigate: Function;
  }
}

registerApplication({
  name: envVars.APP_NAME,
  activeWhen: location => location.pathname.startsWith('/preview'),
  app: () => window.System.import(`/greencare-oraculo/${envVars.APP_NAME}.js`),
  customProps: {
    ...defaultCustomProps,
    basePath: '/preview',
  },
});

if (!window.location.pathname || window.location.pathname === '/') {
  window.singleSpaNavigate('/preview');
}

start();
